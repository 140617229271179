const globalData = {
  data() {
    return {
      platform: [
        { value: null, text: '全部平台' },
        { value: 'facebook', text: 'facebook' },
        { value: 'google', text: 'google' },
        { value: 'tiktok', text: 'tiktok' },
        { value: 'line', text: 'line' },
        { value: 'twitter', text: 'twitter' },
        { value: 'snapchat', text: 'snapchat' },
      ],
      currencyList: [
        { text: '币种', value: null },
        { text: '人民币', value: 'CNY' },
        { text: '美元', value: 'USD' },
        { text: '台币', value: 'TWD' },
        { text: '泰铢', value: 'THB' },
        { text: '港币', value: 'HKD' },
        { text: '日币', value: 'JPY' },
        { text: '马来西亚', value: 'MYR' },
        { text: '新加坡', value: 'SGD' },
        { text: '澳门元', value: 'MOP' },
        { text: '澳大利亚', value: 'AUD' },
        { text: '欧元', value: 'EUR' },
        { text: '韩元', value: 'KRW' },
        { text: '阿联酋迪拉姆', value: 'AED' },
        { text: '印尼', value: 'IDN' },
        { text: '英镑', value: 'GBP' },
        { text: '土耳其', value: 'TRY' },
        { text: '卢比', value: 'INR' },
        { text: '沙特币', value: 'SAR' },
        { text: '越南币', value: 'VND' },
        { text: '朝鲜圆', value: 'KPW' },
        { text: '印尼卢比', value: 'IDR' },
        { text: '菲律宾', value: 'PHP' },
        { text: '巴西雷亚尔', value: 'BRL' },
        { text: '阿根廷比索', value: 'ARS' },
        { text: '兹罗提', value: 'PLN' },
      ],
      sortParamsList: [
        { text: '花费降序', value: 'origin_spend#DESC' },
        { text: '花费升序', value: 'origin_spend#ASC' },
        { text: '出单次数降序', value: 'purchase#DESC' },
        { text: '出单次数升序', value: 'purchase#ASC' },
        { text: '出单成本降序', value: 'order_cost#DESC' },
        { text: '出单成本升序', value: 'order_cost#ASC' },
        { text: '抓取时间降序', value: 'update_time#DESC' },
        { text: '抓取时间升序', value: 'update_time#ASC' },
        { text: '账户名称降序', value: 'ad_account_name#DESC' },
        { text: '账户名称升序', value: 'ad_account_name#ASC' },
      ],
      iconSrc: {
        HIGHT: require('@/assets/level1.png'),
        MID: require('@/assets/level2.png'),
        LOW: require('@/assets/level3.png'),
        LOW2: require('@/assets/level4.png'),
      },
      dateList: [
        {
          text: '今天',
          value: 1,
          start: () => {
            return new Date(new Date(new Date().toLocaleDateString()).getTime());
          },
          end: () => {
            return new Date(new Date(new Date().toLocaleDateString()).getTime());
          },
        },
        {
          text: '昨天',
          value: 2,
          start: () => {
            var date = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() - 3600 * 1000 * 24;
            return new Date(date);
          },
          end: () => {
            var date = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() - 3600 * 1000 * 24;
            return new Date(date);
          },
        },
        {
          text: '过去7天',
          value: 3,
          start: () => {
            const date = new Date(new Date(new Date().toLocaleDateString()).getTime());
            date.setTime(date.getTime() - 3600 * 1000 * (24 * 7));
            return new Date(date);
          },
          end: () => {
            var date = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() - 3600 * 1000 * 24;
            return new Date(date);
          },
        },
        {
          text: '过去14天',
          value: 4,
          start: () => {
            const date = new Date(new Date(new Date().toLocaleDateString()).getTime());
            date.setTime(date.getTime() - 3600 * 1000 * (24 * 14));
            return new Date(date);
          },
          end: () => {
            var date = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() - 3600 * 1000 * 24;
            return new Date(date);
          },
        },
        {
          text: '过去30天',
          value: 5,
          start: () => {
            const date = new Date(new Date(new Date().toLocaleDateString()).getTime());
            date.setTime(date.getTime() - 3600 * 1000 * (24 * 30));
            return new Date(date);
          },
          end: () => {
            var date = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() - 3600 * 1000 * 24;
            return new Date(date);
          },
        },
        {
          text: '本周',
          value: 6,
          start: () => {
            let now = new Date(new Date(new Date().toLocaleDateString()).getTime()),
              d = now.getDay(),
              start;
            start = new Date(now.getTime() - 3600 * 24 * d * 1000);
            return start;
          },
          end: () => {
            return new Date(new Date(new Date().toLocaleDateString()).getTime());
          },
        },
        {
          text: '本月',
          value: 7,
          start: () => {
            let now = new Date(new Date(new Date().toLocaleDateString()).getTime()),
              y = now.getFullYear(),
              m = now.getMonth(),
              start = new Date(y, m, 1);
            return start;
          },
          end: () => {
            return new Date(new Date(new Date().toLocaleDateString()).getTime());
          },
        },
        {
          text: '上月',
          value: 8,
          start: () => {
            let now = new Date(new Date(new Date().toLocaleDateString()).getTime()),
              y = now.getFullYear(),
              m = now.getMonth(),
              start = new Date(y, m - 1, 1);
            return start;
          },
          end: () => {
            let now = new Date(new Date(new Date().toLocaleDateString()).getTime()),
              y = now.getFullYear(),
              m = now.getMonth(),
              end = new Date(y, m, 0);
            return end;
          },
        },
        {
          text: '自定义',
          value: null,
          start: () => {
            return null;
          },
          end: () => {
            return null;
          },
        },
      ],
    };
  },
  methods: {
    formatDate(date, opt) {
      // defhms-"00:00:00", "23:59:59"
      function fnAddZero(params) {
        return (params = params < 10 ? '0' + params : params);
      }
      let y = date.getFullYear(),
        m = date.getMonth() + 1,
        d = date.getDate(),
        h = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds();
      if (opt && opt.tag) {
        return y + '-' + fnAddZero(m) + '-' + fnAddZero(d);
      } else if (opt && opt.defhms) {
        return y + '-' + fnAddZero(m) + '-' + fnAddZero(d) + ' ' + opt.defhms;
      } else {
        return (
          y +
          '-' +
          fnAddZero(m) +
          '-' +
          fnAddZero(d) +
          ' ' +
          fnAddZero(h) +
          ':' +
          fnAddZero(minute) +
          ':' +
          fnAddZero(second)
        );
      }
    },
  },
};
export default globalData;
