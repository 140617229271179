<template>
  <div class="productReport">
    <!-- {{saleMsg}} -->
    <van-grid
      :column-num="1"
      v-for="(item, index) in saleMsg"
      :key="index"
      direction="horizontal"
    >
      <van-grid-item>
        <span>{{ item.name }}:</span>
        <span class="val">{{ item.val }}</span>
      </van-grid-item>
    </van-grid>
    <van-grid :column-num="2" direction="horizontal">
      <van-grid-item v-for="(item, index) in reportList" :key="index">
        <span>{{ item.name }}:</span>
        <span class="val" v-if="item.name !== '经营评估'">{{ formatValue(item.val,item.format) }}</span>
        <span v-else
          ><van-image
            width="20"
            height="20"
            :src="iconSrc[item.val]"
            v-if="item.val"
          />
          <span v-else>--</span></span
        >
      </van-grid-item>
    </van-grid>
  </div>
</template>
<script>
import { Grid, GridItem } from "vant";
import globalData from "@/minixs/data.js";
export default {
  data() {
    return {
      productReport: {},
    };
  },
  mixins: [globalData],
  computed: {
    saleMsg() {
      return [
        { name: "商品名称", val: this.productReport?.productName || "--" },
        { name: "产品名称", val: this.productReport?.goodsName || "--" },
        { name: "商品域名", val: this.productReport?.domain || "--" },
      ];
    },
    reportList() {
      return [
        { name: "商品ID", val: this.productReport?.productId},
        { name: "产品ID", val: this.productReport?.goodsId},
        {
          name: "订单量",
          val: this.productReport.codOrderCount + this.productReport.onlineOrderCount
        },
        { name: "客单价", val: this.productReport?.amountPerCustomer ,format:'fixed'},
        { name: "ROI", val: this.productReport?.roi ,format:'fixed'},
        { name: "ROI临界点", val: this.productReport?.lowestRoi ,format:'fixed'},
        { name: "签收率", val: this.productReport?.signRate,format:'fixed'},
        { name: "选品人", val:  this.valNameOrId('chooser') },
        { name: "设计师", val: this.valNameOrId('designer')},
        { name: "采购占比", val: this.productReport?.buyRate,format:'fixedRate'},
        { name: "运费占比", val: this.productReport?.logisticsRate,format:'fixedRate'},
        { name: "广告费占比", val: this.productReport?.adRate,format:'fixedRate'},
        {
          name: "其他(含退款/经营成本)",
          val: this.productReport?.otherRate,
        },
        { name: "经营评估", val: this.productReport?.profitRateResult},
        { name: "客单量", val: this.productReport?.countPerCustomer,format:'fixed'},
        { name: "平均广告费", val: this.productReport?.avgAdCost,format:'fixed'},
        { name: "广告临界值", val: this.productReport?.adCriticalVal,format:'fixed'},
        { name: "毛利率", val: this.productReport?.profitRatePre,format:'fixedRate'},
        { name: "连续告警", val: this.productReport?.lossDays}
      ];
    },
  },
  methods: {
    valNameOrId(key) {
       return (
          this.productReport[`${key}Name`] ||
          this.productReport[`${key}Id`] ||
          "--"
        );
    },
    formatValue(val,type){
      if(!val) return '--'
      if(type == 'fixed'){
        return val.toFixed(2)
      }else if(type == 'fixedRate'){
        return val.toFixed(2) + '%'
      }else{
        return val
      }
    }
  },
  created() {
    this.productReport = JSON.parse(this.$route.query.val);
    this.productReport.profitRatePre =  this.productReport.profitRatePre || this.productReport.grossMarginRate;
  },
};
</script>
<style lang="scss">
.productReport {
  font-size: 32px;
  .van-grid-item__content--center {
    justify-content: flex-start;
  }
  .van-grid-item__content {
    color: #666;
    .val,.van-image{
      color: #999;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}
</style>